import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetActivityClassesRelevantQueryVariables = Types.Exact<{
  activityId: Types.Scalars['String'];
}>;

export type GetActivityClassesRelevantQuery = {
  __typename?: 'Query';
  ActivityWithRelevantClasses: {
    __typename?: 'ActivityRespond';
    spots: number;
    sorted_classes?: Array<{
      __typename?: 'ActivityClassEntity';
      id: string;
      title: string;
      priceInCents: number;
      spots: number;
      start: any;
      booked: number;
      timelines: Array<{
        __typename?: 'TimelineEntity';
        id: string;
        timelineTeachers?: Array<{
          __typename?: 'TimelineTeacherEntity';
          createdDate?: any | null;
          deletedDate?: any | null;
          id: string;
          teacherId: string;
          timelineId: string;
          updatedDate?: any | null;
          teacher: {
            __typename?: 'MemberEntity';
            country?: string | null;
            email: string;
            id: string;
            name?: string | null;
            postcode?: string | null;
            roles: Array<string>;
            status: string;
            userId: string;
            avatar?: {
              __typename?: 'UserImageEntity';
              userId: string;
              updatedDate?: any | null;
              link: string;
              key: string;
              id: string;
              filename: string;
              deletedDate?: any | null;
              createdDate?: any | null;
            } | null;
          };
        }> | null;
      }>;
    }> | null;
  };
};

export const GetActivityClassesRelevantDocument = gql`
  query GetActivityClassesRelevant($activityId: String!) {
    ActivityWithRelevantClasses(id: $activityId) {
      sorted_classes {
        id
        title
        priceInCents
        spots
        start
        booked
        timelines {
          id
          timelineTeachers {
            createdDate
            deletedDate
            id
            teacherId
            timelineId
            updatedDate
            teacher {
              country
              avatar {
                userId
                updatedDate
                link
                key
                id
                filename
                deletedDate
                createdDate
              }
              email
              id
              name
              postcode
              roles
              status
              userId
            }
          }
        }
      }
      spots
    }
  }
`;

/**
 * __useGetActivityClassesRelevantQuery__
 *
 * To run a query within a React component, call `useGetActivityClassesRelevantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityClassesRelevantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityClassesRelevantQuery({
 *   variables: {
 *      activityId: // value for 'activityId'
 *   },
 * });
 */
export function useGetActivityClassesRelevantQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetActivityClassesRelevantQuery,
    GetActivityClassesRelevantQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetActivityClassesRelevantQuery,
    GetActivityClassesRelevantQueryVariables
  >(GetActivityClassesRelevantDocument, options);
}
export function useGetActivityClassesRelevantLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActivityClassesRelevantQuery,
    GetActivityClassesRelevantQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActivityClassesRelevantQuery,
    GetActivityClassesRelevantQueryVariables
  >(GetActivityClassesRelevantDocument, options);
}
export type GetActivityClassesRelevantQueryHookResult = ReturnType<
  typeof useGetActivityClassesRelevantQuery
>;
export type GetActivityClassesRelevantLazyQueryHookResult = ReturnType<
  typeof useGetActivityClassesRelevantLazyQuery
>;
export type GetActivityClassesRelevantQueryResult = Apollo.QueryResult<
  GetActivityClassesRelevantQuery,
  GetActivityClassesRelevantQueryVariables
>;
